import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useExperiments, useWixSdk} from '@wix/yoshi-flow-editor'
import React, {useEffect, useState} from 'react'
import {SettingsTab, SettingsUpdatedEvent} from '../../../constants'
import {useSettings} from '../../../hooks/settings'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import settingsParams from '../../../settingsParams'
import {useWidgetProps} from '../../hooks/widget-props'
import {Button} from '../button'
import s from './header.scss'
import {MobileHeader} from './mobile-header'
import {PersonalAgendaHeader} from './personal-agenda-header'

export const Header = () => {
  const {
    eventDetailsUrl,
    eventTitle,
    formattedEventDateAndLocation,
    navigate,
    installedApps,
    personalAgendaEnabled,
    forceShowPersonalAgendaUI,
  } = useWidgetProps()
  const {Wix} = useWixSdk()
  const {experiments} = useExperiments()
  const settings = useSettings()
  const {isViewer, isMobile, isEditor} = useEnvironment()
  const {
    allBreakpoints: {isDateAndLocationVisible},
  } = useVisibilityStyles()

  const fixContentOrderEnabled = experiments.enabled(ExperimentNames.FixContentOrder)
  const membersAreaInstalled = installedApps.eventsInMembersInstalled
  const [forceShowPersonalAgenda, setForceShowPersonalAgenda] = useState(false)

  useEffect(() => {
    if (Wix && isEditor) {
      Wix.addEventListener('SETTINGS_UPDATED', event => {
        if (event?.tab === SettingsTab.ButtonDesign) {
          setForceShowPersonalAgenda(event?.type === SettingsUpdatedEvent.ForceShow)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (isEditor) {
      if (forceShowPersonalAgendaUI === SettingsUpdatedEvent.ForceShow) {
        setForceShowPersonalAgenda(true)
      } else {
        setForceShowPersonalAgenda(false)
      }
    }
  }, [forceShowPersonalAgendaUI])

  const renderDateAndLocation = () =>
    isDateAndLocationVisible() ? (
      <div className={fixContentOrderEnabled ? s.dateLocationNew : s.dateLocation}>
        <span data-hook={DH.EVENT_TITLE}>{`${eventTitle} | `}</span>
        <span>{formattedEventDateAndLocation}</span>
      </div>
    ) : null

  if (isMobile) {
    return <MobileHeader alignmentClass={s.alignment} />
  }

  return (
    <div className={s.alignment}>
      {personalAgendaEnabled || forceShowPersonalAgenda ? (
        <PersonalAgendaHeader membersAreaInstalled={membersAreaInstalled} />
      ) : null}
      {fixContentOrderEnabled ? (
        <>
          <h1 className={s.titleNew} data-hook={DH.SCHEDULE_TITLE}>
            {settings.get(settingsParams.scheduleTitleText)}
          </h1>
          {renderDateAndLocation()}
        </>
      ) : (
        <>
          {renderDateAndLocation()}
          <h1 className={s.title} data-hook={DH.SCHEDULE_TITLE}>
            {settings.get(settingsParams.scheduleTitleText)}
          </h1>
        </>
      )}
      <Button eventDetailsUrl={eventDetailsUrl} navigate={navigate} isSite={isViewer} />
    </div>
  )
}
